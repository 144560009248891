import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/mobile_touches.png';

const Content = () => {
  return (
    <div>
      <p>From now, LiveSession playback will display touch events colected on mobile devices.</p>
      <ImageModal
        src={img}
        alt="touch events control"
        title="Displaying touch events colected on mobile devices"
      />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Mobile events support',
  url: '/blog/mobile-actions-support/',
  description: 'See mobile device touches',
  author,
  img,
  date: '2019-05-16',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
